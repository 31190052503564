<template>
  <BodySimple :loading="firstLoading || botLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-bot-96-blue.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">دسترسی های ربات</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ channel.cid }}</div>
    </template>
    <template #default>
      <p class="text-lg font-bold" v-if="res !== false && res.ok == false">
        ربات نتوانست اطلاعات را دریافت کند
      </p>
      <div v-else>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.status == 'administrator' ? okClass : failClass"
        >
          <div class="py-2 font-bold" v-if="res.result.status == 'administrator'">
            <h2>ربات در کانال شما مدیر است</h2>
          </div>
          <div class="py-2 font-bold" v-else><h2>ربات در کانال شما مدیر نیست</h2></div>
        </div>
        <div
          v-if="res.result.is_anonymous"
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="failClass"
        >
          <div class="py-2 font-bold">
            <h2>ربات ادمین ناشناست است</h2>
            <hr class="my-3 border-blueGray-200" />
            <p>
              ربات ادمین ناشناس است. ناشناس بودن ربات ممکن است باعث اختلال جدی در کارکرد
              ربات شود
            </p>
          </div>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_change_info ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی ویرایش اطلاعات</h2></div>
          <h3 class="mt-1 font-bold">can change info</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_change_info ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>
            اگر ربات این درسترسی را داشته باشد میتواند اطلاعات کانال مانند اسم کانال و بیو
            کانال را تغییر دهد. البته ربات بدون اجازه شما چیزی را تغییر نمیدهد. بعضی از
            امکانات پنل به فعال بودن این دسترسی نیاز دارند.
          </p>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_delete_messages ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی حذف پیام ها</h2></div>
          <h3 class="mt-1 font-bold">can delete messages</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_delete_messages ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>با داشتن این دسترسی ربات می‌تواند پیام ها را پاک کند</p>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_edit_messages ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی ویرایش پیام ها</h2></div>
          <h3 class="mt-1 font-bold">can edit messages</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_edit_messages ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>
            با این دسترسی ربات میتواند پیام ها را ویرایش کند. این دسترسی برای قرار دادن
            کلید ها و امضا و ... لازم و ضروری است
          </p>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_invite_users ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی دعوت افراد</h2></div>
          <h3 class="mt-1 font-bold">can invite users</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_invite_users ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>
            اگر ربات این دسترسی را نداشته باشد نمی‌تواند برای کانال لینک بسازد. و همچنین
            نمیتواند این دسترسی را به فرد دیگری بدهد. بهتر است به ربات این دسترسی را بدهید
          </p>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_manage_chat ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی مدیریت چت</h2></div>
          <h3 class="mt-1 font-bold">can manage chat</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_manage_chat ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>این دسترسی برای ربات لازم و ضروری است</p>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_manage_voice_chats ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی مدیریت ویس چت ها</h2></div>
          <h3 class="mt-1 font-bold">can manage voice chats</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_manage_voice_chats ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>
            این دسترسی برای مدیریت ویسچت های کانال است. در حال حاظر ربات نیازی به این
            دسترسی ندارد اما بهتر است فعال باشد
          </p>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_post_messages ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی ارسال پیام</h2></div>
          <h3 class="mt-1 font-bold">can post messages</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_manage_voice_chats ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>این دسترسی برای ارسال پیام توسط ربات لازم و ضروری است</p>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_promote_members ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی ارتقاع کاربران</h2></div>
          <h3 class="mt-1 font-bold">can promote members</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_promote_members ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>
            اگر این دسترسی فعال باشد ربات میتواند کاربران را مدیر کند. دادن این دسترسی
            دلخواه است اما بعضی از قابلیت های ربات به آن وابسته است. همچنین با فعال بودن
            این گزینه اگر به طور ناخواسته اکانت شما حذف شد ربات میتواند اکانت جدید شما را
            مدیر کند
          </p>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";

export default {
  components: {
    BodySimple,
    Icon,
  },
  data() {
    return {
      firstLoading: true,
      botLoading: true,
      error: [],
      channel: {},
      res: {},
      okClass: ["border-green-400"],
      failClass: ["border-red-400"],
    };
  },
  mounted() {
    this.getData();
    this.getAccess();
  },
  methods: {
    getData() {
      let $this = this;
      $this.$axios
        .get("api/owner-channels/" + $this.$route.params.channelId, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
    getAccess() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get("/api/owner-channels/" + $this.$route.params.channelId + "/bot", {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.res = response.data.res;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.botLoading = false;
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
